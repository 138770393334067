<template>
  <div>
    <v-chip
      v-if="!getTagsLoading"
      :color="`${tagColor}2e`"
      :style="{color: tagColor}"
      :close="showDeleteLabel"
      :small="small"
      :data-cy="`tag-${tagId}`"
      @click:close="$emit('delete')"
      @click="filterByTag"
    >
      {{ tagLabel }}
    </v-chip>

    <v-skeleton-loader
      v-else
      type="chip"
    />
  </div>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { mapGetters } from 'vuex';

export default {
  name: 'Tag',
  props: {
    showDeleteLabel: Boolean,
    color: { type: String, required: false, default: '' },
    label: { type: String, required: false, default: '' },
    value: { type: String, required: false, default: '' },
    tagId: { type: String, required: false, default: '' },
    link: { type: String, required: false, default: '' },
    small: Boolean,
  },
  data: () => ({
    tag: {},
    sharedTagKey: '__shared_with_workspace__',
  }),
  computed: {
    ...mapGetters({
      getTag: 'tags/getTag',
      getTags: 'tags/getTags',
      getTagsLoading: 'tags/getTagsLoading',
    }),
    tagColor() {
      return this.color || this.tag?.color;
    },
    tagKey() {
      const tagKey = this.label || this.tag?.name;

      if (tagKey === this.sharedTagKey) {
        return this.$t('deconve.tag.type.shared');
      }

      return tagKey;
    },
    tagValue() {
      return this.value || this.tag?.value;
    },
    tagLabel() {
      if (this.tagKey && this.tagValue) {
        return `${this.tagKey}: ${this.tagValue}`;
      }

      return this.tagKey;
    },
  },
  watch: {
    getTags() {
      this.tag = this.getTag(this.tagId);
    },
  },
  created() {
    if (this.tagId) {
      this.tag = this.getTag(this.tagId);
    }
  },
  methods: {
    filterByTag() {
      if (this.link !== '') {
        this.$router.push({ name: this.link, params: { tagId: this.tagId } });
      }
    },
  },
};
</script>
